"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      padding: "0 16px"
    }
  }, [_c("BackButton"), _vm.bookCategory ? _c("BookSection", {
    attrs: {
      title: _vm.bookCategory.name,
      books: _vm.data,
      visibleMore: false,
      navigateFrom: "other"
    }
  }) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;