"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-drawer", {
    staticClass: "book-chapter-modal",
    attrs: {
      title: "Mục lục",
      visible: _vm.visible,
      direction: _vm.direction,
      "modal-append-to-body": true,
      "append-to-body": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, _vm._l(_vm.computedChapters, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "book-chapter-item",
      class: {
        active: item.id == _vm.selectedChapter.id
      },
      on: {
        click: function click($event) {
          return _vm.handleSelect(item);
        }
      }
    }, [_c("span", {
      staticClass: "dot-selected"
    }), _c("span", {
      staticClass: "chapter-name"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;