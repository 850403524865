"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-general-info"
  }, [_vm._m(0), _c("section", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Phát hành bởi ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.publisher || "--") + " ")])]), _vm.book.bookCategory ? _c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Thể loại ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.bookCategory.name) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Năm xuất bản ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.publishDate || "--") + " ")])]), _c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Nhà xuất bản ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.publisher || "--") + " ")])]), _c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Số trang ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.book.totalPage)) + " trang ")])]), _vm.book.authors ? _c("div", {
    staticClass: "book-general--field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" Tác giả ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.authors.map(function (e) {
    return e.name;
  }).join(", ")) + " ")])]) : _vm._e()])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("section", {
    staticClass: "book-general--title"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("THÔNG TIN CHUNG")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;