"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffTypeTrans = exports.StaffType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _StaffTypeTrans;

var StaffType;
exports.StaffType = StaffType;

(function (StaffType) {
  StaffType["Customer"] = "CUSTOMER";
  StaffType["Teacher"] = "TEACHER";
  StaffType["Staff"] = "STAFF";
  StaffType["Librarian"] = "LIBRARIAN";
  StaffType["Technician"] = "TECHNICIAN"; //Kỹ thuật viên
})(StaffType || (exports.StaffType = StaffType = {}));

var StaffTypeTrans = (_StaffTypeTrans = {}, (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Customer, 'Học sinh'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Teacher, 'Giảng viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Staff, 'Quản trị viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Librarian, 'Thủ thư'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Technician, 'Kỹ thuật viên'), _StaffTypeTrans);
exports.StaffTypeTrans = StaffTypeTrans;