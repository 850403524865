"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "auth-page"
  }, [_c("div", {
    staticStyle: {
      display: "inline-block",
      position: "relative"
    }
  }, [_c("FaceDetection", {
    ref: "FaceDetection",
    attrs: {
      onFaceDetect: _vm.handleDetectFace,
      isFullScreen: _vm.isFullScreen,
      onDetectFail: _vm.handleFaceDetectionFail,
      frameFps: _vm.frameFps,
      id: "login",
      isTest: true
    }
  }), _c("div", {
    staticClass: "control-container",
    class: {
      "full-screen": _vm.isFullScreen
    }
  }, [_c("div", {
    class: {
      "mask-box": _vm.isFullScreen
    }
  }, [_c("div", {
    attrs: {
      id: "description"
    }
  }, [_c("img", {
    attrs: {
      width: "60",
      src: _vm.logoImg
    }
  }), _c("div", {
    attrs: {
      id: "description-title"
    }
  }, [_vm._v("Thư viện Thông minh (v" + _vm._s(_vm.version) + ")")])]), _c("el-button", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "default",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleStart
    }
  }, [_vm._v(_vm._s(_vm.loading ? "Đang" : "Bắt đầu") + " nhận diện")]), _vm.$devMode ? _c("el-button", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "danger",
      size: "default"
    },
    on: {
      click: _vm.handleStop
    }
  }, [_vm._v("Dừng nhận diện")]) : _vm._e()], 1)]), _c("ResultLoginModal", {
    ref: "ResultLoginModal",
    on: {
      close: _vm.handleCloseModal,
      "submit:ok": _vm.handleSubmitOk
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;