"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "highlight-book-page"
  }, [_c("div", {
    staticClass: "highlight-book-container"
  }, [_c("BannerComponent"), _c("el-row", {
    staticStyle: {
      padding: "0 16px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xs: 8,
      span: 6,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "sidebar-box"
  }, [_c("div", {
    staticClass: "sidebar-button",
    on: {
      click: _vm.handleOpenScanner
    }
  }, [_c("img", {
    attrs: {
      src: _vm.scanIcon,
      width: "32",
      alt: ""
    }
  }), _c("span", [_vm._v("Quét mã QR")])])]), _c("BookCategoryPanel", {
    staticStyle: {
      "margin-top": "26px"
    },
    attrs: {
      bookCategories: _vm.bookCategories,
      selectedBookCategoryId: _vm.type == "cate" ? _vm.selectedBookCategoryId : null
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 18,
      xs: 16,
      offset: 0
    }
  }, [_vm.isFetched ? _c("div", [_c("Search", {
    on: {
      search: _vm.handleSearch
    }
  }), _vm._l(_vm.recommendBookStrategies, function (item) {
    return _c("BookSection", {
      key: item.id,
      attrs: {
        title: "".concat(item.name, " (Khuy\u1EBFn ngh\u1ECB)"),
        books: item.books
      },
      on: {
        clickMore: function clickMore() {
          return _vm.handleMoreRecommend(item.id);
        }
      }
    });
  }), _c("BookSection", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      title: "Sách HOT (Nhiều người đọc)",
      books: _vm.hotBooks
    },
    on: {
      clickMore: function clickMore() {
        return _vm.handleMoreBookHot();
      }
    }
  }), _vm.rateBooks.length ? _c("BookSection", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      title: "Sách có lượt đánh giá cao",
      books: _vm.rateBooks
    },
    on: {
      clickMore: function clickMore() {
        return _vm.handleMoreRateBook();
      }
    }
  }) : _vm._e(), _vm.newBooks.length ? _c("BookSection", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      title: "Sách mới",
      books: _vm.newBooks
    },
    on: {
      clickMore: function clickMore() {
        return _vm.handleMoreBookNew();
      }
    }
  }) : _vm._e(), _vm._l(_vm.onAppBookCategories, function (item) {
    return _c("BookSection", {
      key: item.id,
      staticStyle: {
        "margin-top": "20px"
      },
      attrs: {
        title: item.name,
        books: item.books
      },
      on: {
        clickMore: function clickMore() {
          return _vm.handleMoreBookCategory(item.id);
        }
      }
    });
  })], 2) : _vm._e()])], 1), _c("el-backtop", {
    staticClass: "back-to-top",
    attrs: {
      target: ".kios-layout",
      bottom: 100
    }
  }, [_c("div", {
    staticStyle: {
      "{\n      height": "100%",
      width: "100%",
      "background-color": "#fff",
      "box-shadow": "0 0 6px rgba(0,0,0, .12)",
      "text-align": "center",
      "line-height": "50px",
      color: "#9f1c21",
      "border-radius": "50%"
    }
  }, [_vm._v(" Top ")])])], 1), _c("SearchModal", {
    ref: "SearchModal"
  }), _c("QrCodeScannerModal", {
    ref: "QrCodeScannerModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;