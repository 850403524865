"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var authApi = {
  login: function login(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/login',
      data: data,
      method: 'post'
    });
  },
  loginWithUsername: function loginWithUsername(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/login/username',
      data: data,
      method: 'post'
    });
  },
  profile: function profile() {
    return (0, _request.default)({
      url: '/v1/admin/auth/profile'
    });
  },
  updatePassword: function updatePassword(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/password/update',
      method: 'post',
      data: data
    });
  },
  verifyOtp: function verifyOtp(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/otp',
      method: 'post',
      data: data
    });
  },
  reset: function reset(data) {
    return (0, _request.default)({
      url: '/auth/password/reset',
      data: data,
      method: 'post'
    });
  },
  forgot: function forgot(data) {
    return (0, _request.default)({
      url: '/auth/password/forgot',
      data: data,
      method: 'post'
    });
  }
};
exports.authApi = authApi;