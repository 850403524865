"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookApi = void 0;

var _request = require("@/utils/request");

var bookApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/book',
      params: params
    });
  },
  findOne: function findOne(bookId) {
    return (0, _request.request)({
      url: "/v1/admin/book/".concat(bookId)
    });
  },
  findOneByCode: function findOneByCode(code) {
    return (0, _request.request)({
      url: "/v1/admin/book/code",
      params: {
        code: code
      }
    });
  }
};
exports.bookApi = bookApi;