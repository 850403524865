"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-general-info"
  }, [_vm._m(0), _c("section", {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.book.description) + " ")])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("section", {
    staticClass: "book-general--title"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("THÔNG TIN CHUNG")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;