"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "pan-item",
    style: {
      zIndex: _vm.zIndex,
      height: _vm.height,
      width: _vm.width
    }
  }, [_c("div", {
    staticClass: "pan-info"
  }, [_c("div", {
    staticClass: "pan-info-roles-container"
  }, [_vm._t("default")], 2)]), _c("div", {
    staticClass: "pan-thumb",
    style: {
      backgroundImage: "url(".concat(_vm.image, ")")
    }
  })]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;