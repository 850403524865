"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      padding: "0 16px"
    }
  }, [_c("span", {
    staticClass: "back-btn",
    on: {
      click: _vm.handleBack
    }
  }, [_c("img", {
    attrs: {
      src: _vm.backImg,
      width: "40"
    }
  })]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16,
      offset: 0
    }
  }, [_c("BookInfo", {
    attrs: {
      book: _vm.book
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 8,
      offset: 0
    }
  }, [_c("BookGeneralInfo", {
    attrs: {
      book: _vm.book
    }
  })], 1), _c("el-col", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("BookDescription", {
    attrs: {
      book: _vm.book
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;