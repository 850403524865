"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recommendBookStrategyApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var recommendBookStrategyApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/recommendBookStrategy/app',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/recommendBookStrategy/".concat(id)
    });
  }
};
exports.recommendBookStrategyApi = recommendBookStrategyApi;