module.exports = {
  title: "Thư Viện Thông Minh",
  version: '1.0.38',

  /**
   * Text button khi chưa nhận diện
   */
  startDetectionText: 'Bắt đầu nhận diện',

  /**
   * Text button khi đang nhận diện
   */
  loadingDetectionText: 'Đang nhận diện',

  /**
   * panel bottom auth login: Vị trí panel dưới ở trang authen (cách bottom)
   */
  panelAuthPos: 0,
  //đơn vị pixel

  /**
   * Vị trí của thẻ video khi quay dọc màn hình (cách top)
   */
  videoAuthPos: -30,
  //đơn vị pixel

  /**
   * @type {boolean} true | false
   * Check router before access
   */
  checkPermission: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: false,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: "production"
};