"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      padding: "0 16px",
      position: "relative"
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("span", {
    staticClass: "back-btn",
    on: {
      click: _vm.handleBack
    }
  }, [_c("img", {
    attrs: {
      src: _vm.backImg,
      width: "40"
    }
  })]), _c("div", {
    staticClass: "read-now-btn",
    on: {
      click: _vm.handleOpenChapter
    }
  }, [_c("img", {
    attrs: {
      src: _vm.chapterImg,
      width: "24",
      alt: ""
    }
  }), _c("span", [_vm._v("Mục lục")])])])]), _vm.visibleKeyboard ? _c("div", [_c("SimpleKeyboard", {
    attrs: {
      input: _vm.search
    },
    on: {
      onChange: _vm.onChange,
      onKeyPress: _vm.handleKeyPress
    }
  })], 1) : _vm._e(), _vm.isLoaded ? _c("div", [_c("vue-pdf-app", {
    staticStyle: {
      height: "calc(100vh - 100px)"
    },
    attrs: {
      theme: "dark",
      pdf: _vm.$url.image(_vm.bookChapter.pdfLink),
      pageNumber: _vm.page,
      config: _vm.config
    },
    on: {
      open: _vm.handlePdfOpen
    }
  })], 1) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      height: "300px",
      background: "transparent"
    }
  }), _c("BookChapterModal", {
    ref: "BookChapterModal",
    attrs: {
      book: _vm.book,
      selectedChapter: _vm.bookChapter
    },
    on: {
      onSelect: _vm.handleSelectChapter
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;