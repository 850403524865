"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.number.to-fixed.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-item",
    on: {
      click: _vm.handleClick
    }
  }, [_c("div", {
    staticClass: "book-image",
    style: _vm.imageSize ? {
      width: "".concat(_vm.imageSize, "px")
    } : {}
  }, [_c("img", {
    attrs: {
      src: _vm.$url.image(_vm.book.thumbnail),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "book-content"
  }, [_c("section", [_c("span", {
    staticClass: "book-name"
  }, [_vm._v(_vm._s(_vm.book.name))]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Tác giả: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.authors.map(function (e) {
    return e.name;
  }).join(", ")) + " ")])]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Thể loại: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.bookCategory ? _vm.book.bookCategory.name : "--") + " ")])]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Năm xuất bản: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.publishDate ? _vm.book.publishDate : "--") + " ")])]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/StarFull.png"),
      alt: "",
      width: "27"
    }
  })]), _c("span", {
    staticClass: "value",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_vm._v(" " + _vm._s(_vm.book.totalRate ? (_vm.book.totalStar / _vm.book.totalRate).toFixed(1) : "Mới") + " ")]), _vm.book.type == _vm.BookType.EBook ? _c("span", {
    staticClass: "book-type-icon"
  }, [_vm.isPdf ? _c("img", {
    attrs: {
      src: _vm.pdfIcon,
      width: "20",
      alt: ""
    }
  }) : _vm._e(), _vm.isAudio ? _c("img", {
    attrs: {
      src: _vm.audioIcon,
      width: "20",
      alt: ""
    }
  }) : _vm._e(), _vm.isVideo ? _c("img", {
    attrs: {
      src: _vm.videoIcon,
      width: "20",
      alt: ""
    }
  }) : _vm._e()]) : _c("span", [_c("img", {
    attrs: {
      width: "20",
      src: _vm.paperIcon,
      alt: ""
    }
  })])])]), _c("QrcodeVue", {
    staticClass: "qr-code",
    attrs: {
      value: "book--".concat(_vm.book.code),
      size: _vm.qrSize
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;