"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container search-page"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "search-top"
  }, [_c("BackButton", {
    staticClass: "back",
    on: {
      back: _vm.handleDestroy
    }
  }), _c("div", {
    staticClass: "search-bar"
  }, [_c("el-input", {
    ref: "searchInput",
    staticClass: "search-input",
    attrs: {
      value: _vm.query.search,
      placeholder: "Bạn cần tìm sách gì?",
      size: "normal"
    },
    on: {
      focus: _vm.handleFocus,
      input: _vm.onInputChange
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "btn-search",
    on: {
      click: _vm.fetchData
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/search-1.svg"),
      alt: ""
    }
  })])], 1)], 1)]), _vm.visibleKeyboard ? _c("el-button", {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.handleCloseKeyboard
    }
  }, [_vm._v(" Đóng bàn phím ")]) : _vm._e(), _vm.visibleKeyboard ? _c("div", [_c("SimpleKeyboard", {
    attrs: {
      input: _vm.query.search
    },
    on: {
      onChange: _vm.onChange,
      onKeyPress: _vm.handleKeyPress
    }
  })], 1) : _vm._e(), !_vm.data.length ? _c("NoResult") : _vm._e(), _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.data, function (item, index) {
    return _c("el-col", {
      key: index,
      staticStyle: {
        "margin-top": "8px"
      },
      attrs: {
        span: 12,
        offset: 0
      }
    }, [_c("BookItem", {
      attrs: {
        navigateFrom: "search",
        book: item
      }
    })], 1);
  }), 1)], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;