"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "auth-page"
  }, [_c("div", {
    staticStyle: {
      display: "inline-block",
      position: "relative"
    }
  }, [_vm.isInit ? _c("FaceDetection", {
    ref: "FaceDetection",
    attrs: {
      onFaceDetect: _vm.handleDetectOk,
      onDetectFail: _vm.handleDetectFail,
      isFullScreen: _vm.isFullScreen,
      frameFps: _vm.NORMAL_FPS,
      id: "login"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "control-container",
    class: {
      "full-screen": _vm.isFullScreen
    }
  }, [_c("div", {
    class: {
      "mask-box": _vm.isFullScreen
    }
  }, [_c("div", {
    attrs: {
      id: "description"
    }
  }, [_c("img", {
    attrs: {
      width: "60",
      src: _vm.logoImg
    }
  }), _c("div", {
    attrs: {
      id: "description-title"
    }
  }, [_vm._v("Thư viện Thông minh (v" + _vm._s(_vm.version) + ")")])]), _c("el-button", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      size: "default",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleStart
    }
  }, [_vm._v(_vm._s(_vm.loading ? _vm.loadingDetectionText : _vm.startDetectionText) + " "), _vm.$devMode ? [_vm._v(" (" + _vm._s(_vm.status) + ") ")] : _vm._e()], 2)], 1)]), _c("ResultLoginModal", {
    ref: "ResultLoginModal",
    on: {
      close: _vm.handleCloseModal,
      "submit:ok": _vm.handleSubmitOk
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;