"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configurationApi = void 0;

var _request = require("@/utils/request");

var configurationApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/public/configuration',
      params: params
    });
  },
  findByType: function findByType(type) {
    return (0, _request.request)({
      url: '/v1/public/configuration/type',
      params: {
        type: type
      }
    });
  }
};
exports.configurationApi = configurationApi;