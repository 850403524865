// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/background-app-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body[data-v-326dc630] {\n  margin: 25px;\n}\n.auth-page[data-v-326dc630] {\n  position: relative;\n  margin: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  min-height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n}\n.control-container[data-v-326dc630] {\n  position: relative;\n  z-index: 99;\n}\n.control-container.full-screen[data-v-326dc630] {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    bottom: 0;\n    position: fixed;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    width: 100vw;\n}\n.control-container .mask-box[data-v-326dc630] {\n    background: rgba(0, 0, 0, 0.5);\n    color: #f1f1f1;\n    border-radius: 5px;\n    padding: 16px;\n}\n#description[data-v-326dc630] {\n  margin-top: 20px;\n  width: 600px;\n}\n#description-title[data-v-326dc630] {\n  font-weight: bold;\n  font-size: 18px;\n}\n", ""]);
// Exports
module.exports = exports;
