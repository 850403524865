"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("VueAutoNumeric", _vm._b({
    ref: "input",
    staticClass: "el-input__inner",
    attrs: {
      value: _vm.value,
      options: (0, _objectSpread2.default)({
        allowDecimalPadding: false,
        digitGroupSeparator: ",",
        decimalCharacter: "."
      }, _vm.options)
    },
    on: {
      input: _vm.handleInput
    },
    nativeOn: {
      blur: function blur($event) {
        return _vm.handleBlur.apply(null, arguments);
      }
    }
  }, "VueAutoNumeric", _vm.$attrs, false));
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;