"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("div", {
    ref: "videoContainer",
    staticClass: "video-container",
    class: {
      "full-screen": _vm.isFullScreen
    }
  }, [_c("div", [_c("video", {
    ref: "video",
    style: "transform: scaleX(-1);",
    attrs: {
      id: "video",
      playsinline: ""
    }
  }), _c("canvas", {
    ref: "canvas",
    attrs: {
      id: "output"
    }
  }), _vm.isTest ? _c("div", {
    staticClass: "result-img-origin"
  }) : _vm._e()]), _vm.isTest ? _c("div", {
    staticClass: "result-img"
  }) : _vm._e()])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;