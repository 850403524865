"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "result-qrcode-modal",
    attrs: {
      title: "",
      visible: _vm.visible,
      width: "700px",
      center: "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.onClose
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "min-height": "50px"
    }
  }, [_vm.status == "ok" ? _c("section", [_c("span", {
    staticClass: "title"
  }, [_vm._v(" Đã tìm được ")]), _c("BookItem", {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      book: _vm.book,
      qrSizeInit: 80,
      imageSize: 150
    }
  })], 1) : _c("section", {
    staticClass: "text-center"
  }, [_c("span", {
    staticClass: "title",
    staticStyle: {
      color: "red !important"
    }
  }, [_vm._v(" Không tìm được dữ liệu ")])])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [!_vm.loading ? [_vm.status == "ok" ? [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Bỏ qua")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.onSubmitOk
    }
  }, [_vm._v("Xem ngay")])] : _c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("el-button", {
    staticStyle: {
      "padding-left": "36px",
      "padding-right": "36px",
      "border-radius": "26px"
    },
    attrs: {
      type: "primary",
      size: "default"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("OK")])], 1)] : _vm._e()], 2)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;