"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerApi = void 0;

var _request = require("@/utils/request");

var bannerApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/banner',
      params: params
    });
  }
};
exports.bannerApi = bannerApi;