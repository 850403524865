"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "no-result"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/Book.svg"),
      alt: ""
    }
  }), _vm._m(0), _c("div", {
    staticClass: "title"
  }, [_vm._v(" Sách kinh tế, sách kỹ năng mềm ")])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "note"
  }, [_c("span", [_vm._v("Gõ tiếng Việt có dấu khi tìm sách tiếng Việt, để tăng độ chính xác khi tìm kiếm")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;