"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {}, [_c("span", {
    staticClass: "back-btn",
    on: {
      click: _vm.handleBack
    }
  }, [_c("img", {
    attrs: {
      src: _vm.backImg,
      width: "40"
    }
  })])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;