"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'token';

function getToken() {
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTcsInR5cGUiOiJBRE1JTiIsIm1hY0FkZHJlc3MiOiIiLCJpYSI6MTY2ODIzNzMzNiwiaWF0IjoxNjY4MjM3MzM2LCJleHAiOjE2OTkzNDEzMzZ9.eiZ7R7GfYCMjepxYYzquGtNtEwkvkbdpUIycCSanyqE';
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}