"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "book-meta-modal",
    attrs: {
      title: "Xem meta s\xE1ch",
      visible: _vm.visible,
      "append-to-body": "",
      width: "500px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("label", {
    staticStyle: {
      color: "black"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("QR code: ")])]), _vm.book.syncId ? _c("img", {
    attrs: {
      width: "150",
      src: "https://api.qrserver.com/v1/create-qr-code/?data=https://opac.thuvienhcma1.vn/record=b".concat(_vm.book.syncId)
    }
  }) : _c("span", [_vm._v(" Chưa có dữ liệu ")])]), _c("div", {
    staticStyle: {
      "text-align": "center",
      "margin-top": "16px"
    }
  }, [_c("label", {
    staticStyle: {
      color: "black"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Meta: ")])]), _c("Swiper", {
    attrs: {
      options: _vm.swiperOptionsObject
    }
  }, [_vm._l(_vm.book.bookMeta, function (item, index) {
    return _c("SwiperSlide", {
      key: index
    }, [_c("div", {
      staticClass: "barcode-item"
    }, [_c("barcode", {
      attrs: {
        value: item.barcode
      }
    }, [_vm._v(" Render fail ")]), _c("div", [_c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Vị trí: ")]), _c("span", [_vm._v(_vm._s(item.bookLocation ? item.bookLocation.name : "--"))])]), _c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Barcode: ")]), _c("span", [_vm._v(_vm._s(item.barcode))])]), _c("div", [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v("Call number: ")]), _c("span", [_vm._v(_vm._s(item.callNumber))])])])], 1)]);
  }), _c("div", {
    staticClass: "swiper-pagination",
    attrs: {
      slot: "pagination"
    },
    slot: "pagination"
  })], 2), _c("div", {
    staticClass: "swiper-button-next"
  }), _c("div", {
    staticClass: "swiper-button-prev"
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("div")], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;