"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "text-center box"
  }, [_c("img", {
    attrs: {
      width: "50",
      src: _vm.icon
    }
  }), _c("br"), _c("p", {
    staticClass: "font-bold size-large primary-color"
  }, [_vm._v(_vm._s(_vm.title))])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;