"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Nhận diện thất bại",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      "show-close": false,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Hệ thống phát hiện học viên " + _vm._s(_vm.userInfo.name) + " không có mặt tại Kiosk, nếu bạn vẫn đang đọc sách, nhấn Tiếp tục ")])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleContinue
    }
  }, [_vm._v("Tiếp tục " + _vm._s(_vm.countDown) + "(s)")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;