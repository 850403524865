"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    staticClass: "book-qrcode-modal",
    attrs: {
      visible: _vm.visible,
      width: "600px ",
      center: "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c("section", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "600",
      color: "#000",
      "font-size": "14px"
    }
  }, [_vm._v(" Quét mã QRCode này để xem sách trên các thiết bị khác. ")]), _c("br"), _c("span", {
    staticStyle: {
      "font-weight": "600",
      color: "#9F1C20",
      "font-size": "14px",
      "margin-top": "14px",
      display: "inline-block",
      "margin-bottom": "14px"
    }
  }, [_vm._v(" ISBN: " + _vm._s(_vm.book.isbnCode || "--") + " ")]), _c("QrcodeVue", {
    attrs: {
      value: "book--".concat(_vm.book.code),
      size: 200
    }
  })], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("el-button", {
    staticStyle: {
      "padding-left": "36px",
      "padding-right": "36px",
      "border-radius": "26px"
    },
    attrs: {
      type: "primary",
      size: "default"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("OK")])], 1)])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;