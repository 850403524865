"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;

require("core-js/modules/es.object.to-string.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _index = _interopRequireDefault(require("@/layout/index.vue"));

var _Kios = _interopRequireDefault(require("@/layout/Kios.vue"));

var _staff = require("@/types/staff");

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  name: "auth",
  hidden: true
}, {
  path: "/auth-test",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth-test/index.vue"));
    });
  },
  name: "auth-test",
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/home",
  hidden: true
}, {
  path: "/home",
  component: _Kios.default,
  meta: {
    title: "Trang chủ",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/public-recommend-book-strategy/index.vue"));
      });
    },
    name: "home-index",
    meta: {
      title: "Trang chủ",
      permission: true
    }
  }]
}, {
  path: "/search",
  component: _Kios.default,
  meta: {
    title: "Tìm kiếm",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/search/index.vue"));
      });
    },
    name: "search",
    meta: {
      title: "Tìm kiếm",
      permission: true
    }
  }]
}, {
  path: "/detail",
  component: _Kios.default,
  meta: {
    title: "",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/category-detail/index.vue"));
      });
    },
    name: "book-category",
    meta: {
      title: "",
      permission: true
    }
  }, {
    path: "hot",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/hot-category/index.vue"));
      });
    },
    name: "book-hot",
    meta: {
      title: "",
      permission: true
    }
  }, {
    path: "recommend",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend-detail/index.vue"));
      });
    },
    name: "book-recommend",
    meta: {
      title: "",
      permission: true
    }
  }, {
    path: "new",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/new-category/index.vue"));
      });
    },
    name: "book-new",
    meta: {
      title: "",
      permission: true
    }
  }, {
    path: "rate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/rate-book/index.vue"));
      });
    },
    name: "book-rate",
    meta: {
      title: "",
      permission: true
    }
  }]
}, {
  path: "/book",
  component: _Kios.default,
  redirect: '/book/:id',
  meta: {
    title: "",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: ":id",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/book-detail/index.vue"));
      });
    },
    name: "detail-book",
    meta: {
      title: "Chi tiết sách",
      permission: true
    }
  }, {
    path: ":id/read",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/read-book/index.vue"));
      });
    },
    name: "read-book",
    meta: {
      title: "Đọc sách",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "Cá nhân",
    icon: "qq",
    permission: false,
    roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Cá nhân",
    meta: {
      title: "Cá nhân",
      permission: false,
      roles: [_staff.StaffType.Staff, _staff.StaffType.Librarian]
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
}

var _default = router;
exports.default = _default;