"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", [_c("img", {
    attrs: {
      width: "40",
      src: _vm.imgBook,
      alt: ""
    }
  }), _c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.title))])]), _vm.visibleMore ? _c("span", {
    staticClass: "more",
    on: {
      click: _vm.handleClick
    }
  }, [_vm._v(" Xem thêm ")]) : _vm._e()]), _c("div", {
    staticClass: "content"
  }, _vm._l(_vm.books, function (book, index) {
    return _c("BookItem", {
      key: index,
      staticStyle: {
        height: "100%"
      },
      attrs: {
        book: book,
        navigateFrom: _vm.navigateFrom
      }
    });
  }), 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;