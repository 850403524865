"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "input-mask",
    class: {
      active: _vm.isFocus
    }
  }, [_c("div", {
    staticClass: "prep-end"
  }, [_vm._t("prepend")], 2), _c("input", _vm._b({
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    staticClass: "el-input__inner",
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.value
    },
    on: {
      input: _vm.handleInput,
      focus: _vm.handleFocus,
      blur: _vm.handleBlur
    }
  }, "input", _vm.$attrs, false))]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;