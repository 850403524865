"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticStyle: {
      padding: "0 16px"
    }
  }, [_c("Swiper", {
    attrs: {
      options: _vm.swiperOptionsObject
    }
  }, _vm._l(_vm.data, function (banner, i) {
    return _c("SwiperSlide", {
      key: i
    }, [_c("div", {
      staticClass: "banner-item"
    }, [_c("img", {
      attrs: {
        src: _vm.$url.image(banner.image)
      }
    })])]);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;