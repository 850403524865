"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookCategoryApi = void 0;

var _request = require("@/utils/request");

var bookCategoryApi = {
  findAllSummary: function findAllSummary() {
    return (0, _request.request)({
      url: '/v1/admin/bookCategory/summary'
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/admin/bookCategory/".concat(id)
    });
  },
  findOnApp: function findOnApp(params) {
    return (0, _request.request)({
      url: '/v1/admin/bookCategory/app',
      params: params
    });
  }
};
exports.bookCategoryApi = bookCategoryApi;