"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookType = void 0;
var BookType;
exports.BookType = BookType;

(function (BookType) {
  BookType["Physical"] = "PHYSICAL";
  BookType["EBook"] = "E-BOOK"; //sách điện tử
})(BookType || (exports.BookType = BookType = {}));