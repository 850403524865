"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "kios-layout",
    on: {
      scroll: _vm.handleScroll
    }
  }, [!_vm.$devMode && _vm.isReadyFaceDetection ? _c("FaceDetection", {
    ref: "FaceDetection",
    style: {
      opacity: _vm.faceCameraOpacity
    },
    attrs: {
      id: "home",
      isFullScreen: true,
      onFaceDetect: _vm.handleFaceDetectionOk,
      onDetectFail: _vm.handleFaceDetectionFailFromCamera,
      frameFps: _vm.frameFps
    }
  }) : _vm._e(), _c("div", {
    staticClass: "b-container",
    style: {
      position: "relative",
      zZndex: 2,
      opacity: _vm.contentKiosOpacity
    }
  }, [_vm.isAuth ? _c("div", {
    staticClass: "kios-header"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "user-info"
  }, [!_vm.info.avatar ? _c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.defaultAvatar
    }
  }) : _c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.$url.image(_vm.info.avatar)
    }
  }), _c("span", {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.info.name) + " ")])])]), _c("div", {
    staticClass: "center"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", [_c("span", {
    staticClass: "sub-title"
  }, [_vm._v(" " + _vm._s(_vm.subTitle) + " ")])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "logout-btn",
    on: {
      click: _vm.handleLogout
    }
  }, [_c("i", {
    staticClass: "el-icon-switch-button"
  }), _c("span", [_vm._v("Đăng xuất")])])])]) : _vm._e(), _c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.cacheViews
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1)], 1)], 1), _c("FaceDetectFailModal", {
    ref: "FaceDetectFailModal",
    on: {
      continue: _vm.handleContinue,
      cancel: _vm.handleCancelFaceDetectFail
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;