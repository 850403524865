"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-category-panel"
  }, [_c("el-collapse", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", {
    staticClass: "panel-title"
  }, [_vm._v(" Danh mục ")])]), _vm._l(_vm.bookCategories, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "recommend-book-item",
      class: {
        active: item.id == _vm.selectedBookCategoryId
      },
      on: {
        click: _vm.handleClickCategory
      }
    }, [_c("router-link", {
      attrs: {
        to: {
          name: "book-category",
          query: {
            bookCategoryId: item.id + "",
            orient: _vm.$route.query.orient,
            pos: _vm.$route.query.pos
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.totalBook) + ") ")])], 1);
  })], 2)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;