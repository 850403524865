"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.formatDateDay = formatDateDay;
exports.formatDateTime = formatDateTime;
exports.generateDuration = generateDuration;
exports.generateTimeSeries = generateTimeSeries;
exports.secondToMinuteString = secondToMinuteString;

require("core-js/modules/es.array.concat.js");

var _moment = _interopRequireDefault(require("moment"));

function formatDate(timestamp) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
  return _moment.default.unix(timestamp).format(format);
}

function formatDateTime(timestamp) {
  return _moment.default.unix(timestamp).format("HH:mm, DD/MM/YYYY");
}

function formatDateDay(timestamp) {
  return _moment.default.unix(timestamp).format("dddd, MM-DD-YYYY");
}

function generateTimeSeries(step) {
  var addHour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'h:mm a';
  var dt = new Date(1970, 0, 1);
  var rc = [];

  while (dt.getDate() === 1) {
    rc.push((0, _moment.default)(dt).add(addHour, 'hour').format(format)); // rc.push(dt.toLocaleTimeString('en-US'))

    dt.setMinutes(dt.getMinutes() + step);
  }

  return rc;
}

function generateDuration() {
  var seconds = 300;
  var durations = [];

  while (seconds <= 60 * 60 * 12) {
    var h = Math.floor(seconds / 3600);
    var m = seconds % 3600 / 60;
    var label = secondToMinuteString(seconds);
    durations.push({
      label: label,
      value: seconds
    });
    seconds += 300;
  }

  return durations;
}

function secondToMinuteString(second) {
  var h = Math.floor(second / 3600);
  var m = second % 3600 / 60;
  var label = "";

  if (h == 0) {
    label = "".concat(m, "m");
  } else {
    if (m == 0) {
      label = "".concat(h, "h");
    } else {
      label = "".concat(h, "h ").concat(m, "m");
    }
  }

  return label;
}