"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-info"
  }, [_c("div", {
    staticClass: "book-img"
  }, [_c("img", {
    attrs: {
      src: _vm.$url.image(_vm.book.thumbnail),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "book-content"
  }, [_c("section", [_c("div", {
    staticClass: "book-name"
  }, [_vm._v(" " + _vm._s(_vm.book.name) + " ")]), _vm._m(0), _c("div", {
    staticClass: "book-star"
  }, [_c("img", {
    attrs: {
      src: _vm.starImg,
      width: "24",
      alt: ""
    }
  }), _c("span", [_vm._v("4")])])]), _c("section", [_c("div", {
    staticClass: "qr-code",
    on: {
      click: _vm.handleClickQrCode
    }
  }, [_c("span", {
    staticClass: "qr-code-box"
  }, [_c("QrcodeVue", {
    staticClass: "qr-code-vue",
    attrs: {
      value: "book--".concat(_vm.book.code),
      size: 40
    }
  })], 1), _c("span", {
    staticClass: "qr-code-content"
  }, [_vm._v(" ISBN: " + _vm._s(_vm.book.isbnCode || "--") + " "), _c("br"), _vm._v(" Bấm vào mã để quét ")])]), _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm.book.type == _vm.BookType.EBook && _vm.isPdf ? _c("div", {
    staticClass: "read-now-btn",
    on: {
      click: _vm.handleRead
    }
  }, [_c("img", {
    attrs: {
      src: _vm.bookImg,
      width: "24",
      alt: ""
    }
  }), _c("span", [_vm._v("Đọc ngay")])]) : _vm._e(), _vm.book.type == _vm.BookType.Physical ? _c("div", {
    staticClass: "read-now-btn",
    on: {
      click: _vm.openMetaBook
    }
  }, [_c("img", {
    attrs: {
      src: _vm.bookImg,
      width: "24",
      alt: ""
    }
  }), _c("span", [_vm._v("Mượn sách")])]) : _vm._e()])])]), _c("BookQrCodeDetailModal", {
    ref: "BookQrCodeDetailModal"
  }), _c("BookMetaModal", {
    ref: "BookMetaModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "book-label"
  }, [_vm._v(" Tác giả: ")]), _c("span", {
    staticClass: "book-value"
  }, [_vm._v(" John Brooks ")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;