"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigurationType = void 0;
var ConfigurationType;
exports.ConfigurationType = ConfigurationType;

(function (ConfigurationType) {
  ConfigurationType["AutoApproveRecommendBook"] = "AUTO_APPROVE_RECOMMEND_BOOK";
  ConfigurationType["FaceCameraOpacity"] = "FACE_CAMERA_OPACITY";
  ConfigurationType["ContentKiosOpacity"] = "CONTENT_KIOS_OPACITY";
  ConfigurationType["FaceDetectionTime"] = "FACE_DETECTION_TIME";
  ConfigurationType["FaceDetectionMinute"] = "FACE_DETECTION_MINUTE";
  ConfigurationType["FaceDetectionTimeOut"] = "FACE_DETECTION_TIME_OUT";
  ConfigurationType["FaceDetectionSecondFail"] = "FACE_DETECTION_SECOND_FAIL";
  ConfigurationType["FaceDetectionPopupLimit"] = "FACE_DETECTION_POPUP_LIMIT";
  ConfigurationType["FaceDetectDomain"] = "FACE_DETECT_DOMAIN";
  ConfigurationType["FaceDetectUsername"] = "FACE_DETECT_USERNAME";
  ConfigurationType["FaceDetectPassword"] = "FACE_DETECT_PASSWORD";
})(ConfigurationType || (exports.ConfigurationType = ConfigurationType = {}));