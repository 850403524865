import { render, staticRenderFns } from "./index.vue?vue&type=template&id=735bb5ca&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('735bb5ca')) {
      api.createRecord('735bb5ca', component.options)
    } else {
      api.reload('735bb5ca', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=735bb5ca&", function () {
      api.rerender('735bb5ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hot-category/index.vue"
export default component.exports