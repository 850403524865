"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "AppMain",
  computed: {
    cachedViews: function cachedViews() {
      return ["home"];
    },
    key: function key() {
      return this.$route.path;
    }
  }
};
exports.default = _default;