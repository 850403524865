import { render, staticRenderFns } from "./BookChapterModal.vue?vue&type=template&id=c45c8c2e&scoped=true&"
import script from "./BookChapterModal.vue?vue&type=script&lang=ts&"
export * from "./BookChapterModal.vue?vue&type=script&lang=ts&"
import style0 from "./BookChapterModal.vue?vue&type=style&index=0&id=c45c8c2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c45c8c2e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c45c8c2e')) {
      api.createRecord('c45c8c2e', component.options)
    } else {
      api.reload('c45c8c2e', component.options)
    }
    module.hot.accept("./BookChapterModal.vue?vue&type=template&id=c45c8c2e&scoped=true&", function () {
      api.rerender('c45c8c2e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/read-book/components/BookChapterModal.vue"
export default component.exports