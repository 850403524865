"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Thông tin",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      close: _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("h3", {
    staticClass: "text-center",
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Xác nhận thông tin của bạn")]), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.staff.avatar ? _c("img", {
    staticStyle: {
      width: "80px",
      height: "80px",
      "border-radius": "50%"
    },
    attrs: {
      src: _vm.$url.image(_vm.staff.avatar),
      alt: ""
    }
  }) : _c("img", {
    staticStyle: {
      width: "80px",
      height: "80px",
      "border-radius": "50%"
    },
    attrs: {
      src: _vm.defaultAvatar,
      alt: ""
    }
  }), _c("br"), _c("label", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.staff.name) + " ")])])]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("Không phải tôi!")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("Xác nhận thông tin")])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;