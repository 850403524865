"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateBBox = void 0;

var generateBBox = function generateBBox(_ref) {
  var x = _ref.x,
      y = _ref.y,
      width = _ref.width,
      height = _ref.height,
      cameraWidth = _ref.cameraWidth,
      cameraHeight = _ref.cameraHeight,
      _ref$widthScale = _ref.widthScale,
      widthScale = _ref$widthScale === void 0 ? 0.25 : _ref$widthScale,
      _ref$heightScale = _ref.heightScale,
      heightScale = _ref$heightScale === void 0 ? 0.5 : _ref$heightScale;
  var xCrop = Math.max(x - width * widthScale, 0);
  var widthCrop = Math.min(width + 2 * widthScale * width, cameraWidth - xCrop);
  var yCrop = Math.max(y - height * heightScale, 0);
  var heightCrop = Math.min(height + 2 * heightScale * height, cameraHeight - yCrop);
  var bBox = [Math.round(Math.max(y - yCrop - height / 10, 0)), Math.round(Math.max(x - xCrop, 0)), Math.round(Math.max(y - yCrop - height / 10, 0) + height), Math.round(Math.max(x - xCrop, 0) + width)];
  return {
    x: xCrop,
    y: yCrop,
    width: widthCrop,
    height: heightCrop,
    bBox: bBox
  };
};

exports.generateBBox = generateBBox;