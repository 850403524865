"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookMemoryApi = void 0;

var _request = require("@/utils/request");

var bookMemoryApi = {
  findLast: function findLast() {
    return (0, _request.request)({
      url: '/v1/admin/bookMemory/last'
    });
  },
  findOne: function findOne(bookId) {
    return (0, _request.request)({
      url: "/v1/admin/bookMemory/".concat(bookId)
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: "/v1/admin/bookMemory",
      method: 'post',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/bookMemory/".concat(id, "/update"),
      method: 'patch',
      data: data
    });
  }
};
exports.bookMemoryApi = bookMemoryApi;