"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "search-bar",
    on: {
      click: _vm.handleClick
    }
  }, [_c("span", {
    staticClass: "search-text"
  }, [_vm._v(" " + _vm._s(_vm.search ? _vm.search : "Bạn cần tìm sách gì?") + " ")]), _c("div", {
    staticClass: "btn-search"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/search-1.svg"),
      alt: ""
    }
  })])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;