"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCookie = exports.getCookie = exports.CookieType = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var CookieType;
exports.CookieType = CookieType;

(function (CookieType) {
  CookieType["LastId"] = "lastID";
  CookieType["StatusLogin"] = "statusLogin";
  CookieType["SessionLogin"] = "sessionLogin";
  CookieType["IsOnTop"] = "isOnTop";
  CookieType["ExpiredAt"] = "EXPIRED_AT";
  CookieType["DetectionTime"] = "detectionTime";
  CookieType["DetectionRequest"] = "detectionRequest";
  CookieType["DetectionResponse"] = "detectionResponse";
})(CookieType || (exports.CookieType = CookieType = {}));

var setCookie = function setCookie(type, value) {
  _jsCookie.default.set(type, value);
};

exports.setCookie = setCookie;

var getCookie = function getCookie(type) {
  return _jsCookie.default.get(type);
};

exports.getCookie = getCookie;