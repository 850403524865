import { render, staticRenderFns } from "./Kios.vue?vue&type=template&id=62b68644&scoped=true&"
import script from "./Kios.vue?vue&type=script&lang=ts&"
export * from "./Kios.vue?vue&type=script&lang=ts&"
import style0 from "./Kios.vue?vue&type=style&index=0&id=62b68644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b68644",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62b68644')) {
      api.createRecord('62b68644', component.options)
    } else {
      api.reload('62b68644', component.options)
    }
    module.hot.accept("./Kios.vue?vue&type=template&id=62b68644&scoped=true&", function () {
      api.rerender('62b68644', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/Kios.vue"
export default component.exports