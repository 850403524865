"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logApi = void 0;

var _request = require("@/utils/request");

var logApi = {
  create: function create(text) {
    return (0, _request.request)({
      url: '/v1/public/log/kiosk/error',
      method: 'post',
      data: {
        text: text
      }
    });
  }
};
exports.logApi = logApi;