"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-kiosk/smartlib-kiosk/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;
exports.secondToMinutes = exports.formatPhone = void 0;

require("core-js/modules/es.number.to-fixed.js");

var _moment = _interopRequireDefault(require("moment"));

require("moment-duration-format");

var _libphonenumberJs = _interopRequireDefault(require("libphonenumber-js"));

function formatCurrency(n) {
  return new Intl.NumberFormat('Vi', {
    style: 'currency',
    currency: 'VND'
  }).format(n);
}

var formatPhone = function formatPhone(callingCode, phone) {
  var res = (0, _libphonenumberJs.default)("".concat(phone));
  return res ? res.formatInternational() : '';
};

exports.formatPhone = formatPhone;

function formatNumberVN(n) {
  var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ',';

  if (isNaN(n)) {
    return '';
  }

  n = (+n || 0).toFixed(fixed);
  return new Intl.NumberFormat('de-DE').format(n); // return 
  //   .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, separator));
}

var secondToMinutes = function secondToMinutes(seconds) {
  var hour = Math.floor(_moment.default.duration(seconds, 'seconds').asHours());

  var minute = _moment.default.duration(seconds, 'seconds').asMinutes();

  var second = _moment.default.duration(seconds, 'seconds').seconds();

  if (second < 10) {
    second = "0".concat(second);
  }

  if (seconds > 3600) {
    return hour + 'h ' + (minute - 60 * hour) + 'm ' + second;
  }

  return minute + 'm ' + second;
};

exports.secondToMinutes = secondToMinutes;