"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "sidebar-logo-container",
    class: {
      collapse: _vm.collapse
    }
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_vm.collapse ? _c("router-link", {
    key: "collapse",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "sidebar-logo",
    attrs: {
      src: _vm.logo
    }
  })]) : _c("router-link", {
    key: "expand",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "sidebar-logo",
    attrs: {
      src: _vm.logo
    }
  })])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;